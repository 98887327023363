<template>
  <div class="template">
    <div>
      <div class="form-search-box">
        <a-form layout="inline">
          <!-- <a-form-item label="关键字">
						<a-input v-model="search.keyword" placeholder="输入关键字"></a-input>
					</a-form-item>
					<a-form-item label="应用">
						<a-select style="width: 200px;" v-model="search.appid">
							<a-select-option :value="0">全部</a-select-option>
							
							<a-select-option v-for="(item,index) in  apps" :key="index" :value="item.app_id">{{item.name}}</a-select-option>
						</a-select>
					</a-form-item> -->

          <a-form-item>
            <!-- <a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button> -->
            <a-button @click="addAct" icon="plus" class="ml10" type="primary"
              >添加模版</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </div>

    <a-modal
      title="制作模版"
      :visible="showApps"
      :footer="null"
      width="900px"
      @cancel="handleAppsCancel"
    >
      <div class="flex wrap">
        <div v-for="(item, index) in apps" :key="index" class="app-item">
          <router-link :to="'/template/' + item.iden + '?appId=' + item.app_id">
            <div class="text-center">
              <img style="width: 90px; height: 90px" :src="item.logo" />
            </div>
            <div class="mt16 text-center">{{ item.name }}</div>
          </router-link>
        </div>
      </div>
    </a-modal>
    <div class="mt20">
      <div class="wxb-table-white">
        <a-table
          :scroll="{ x: true }"
          rowKey="template_id"
          :columns="columns"
          :pagination="pagination"
          @change="handleTableChange"
          :data-source="datas"
        >
          <div slot="app" slot-scope="app, record">
            {{ app.name }}
          </div>

          <div slot="photo" slot-scope="photo, record">
            <img :src="photo" style="width: 60px; height: 60px" />
          </div>

          <div slot="is_online" slot-scope="is_online, record">
            <a-tag v-if="is_online == 0">下架中</a-tag>
            <a-tag v-else color="#f50">上架中</a-tag>
            <a-button @click="onlineAct(record)" type="link">
              {{ is_online == 0 ? "上架" : "下架" }}
            </a-button>
          </div>

          <template slot="action" slot-scope="text, record">
            <div class="flex center">
              <router-link
                :to="
                  '/template/' +
                  record.iden +
                  '?templateId=' +
                  record.template_id
                "
              >
                <a-button type="link">编辑</a-button>
              </router-link>
              <a-button @click="delAct(record.template_id)" type="link"
                >删除</a-button
              >
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { listMixin } from "../../common/mixin/list.js";
export default {
  mixins: [listMixin],
  data() {
    return {
      loading: false,
      pagination: {
        current: 1,
        pageSize: 50, //每页中显示10条数据
        total: 0,
      },
      search: {
        keyword: "",
        appid: 0,
      },
      columns: [
        {
          title: "编号",
          dataIndex: "template_id",
          align: "center",
          ellipsis: true,
        },
        { title: "模版", dataIndex: "name", align: "center", ellipsis: true },
        // {title: '所属应用',dataIndex: 'app',align: 'center',scopedSlots: {customRender: 'app'}},
        {
          title: "缩略图",
          dataIndex: "photo",
          align: "center",
          scopedSlots: { customRender: "photo" },
        },
        {
          title: "状态",
          dataIndex: "is_online",
          align: "center",
          scopedSlots: { customRender: "is_online" },
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      datas: [],
      apps: [],
      showApps: false,
    };
  },
  mounted() {
    // document.querySelector('.ant-table').style="height:calc(100vh - 250px)"
  },
  methods: {
    handleAppsCancel() {
      this.showApps = false;
    },
    addAct() {
      this.$router.push("/template/beautiful?appId=1");
      //this.showApps = true;
    },
    delAct(e) {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/admin/delTemplate", [e])
        .then((res) => {
          this.loading = false;
          this.$message.success("操作成功");
          this.getLists();
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    onlineAct(e) {
      console.log(e);
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/admin/onlineTemplate", {
          templateId: e.template_id,
        })
        .then((res) => {
          this.loading = false;
          this.$message.success("操作成功");
          this.getLists();
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    getLists() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/admin/getTemplateList", {
          keyword: this.search.keyword,
          limit: this.pagination.pageSize,
          page: this.pagination.current,
          appid: this.search.appid,
        })
        .then((res) => {
          this.pagination.total = res.total;
          this.datas = res.datas;
          this.apps = res.apps;
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getLists();
    },
  },
};
</script>

<style>
.app-item {
  width: 196px;
  height: 180px;
  border: 1px solid #ebedf5;
  border-radius: 8px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 20px;
  cursor: pointer;
}
.app-item:nth-child(4n) {
  margin-right: 0px;
}
</style>
